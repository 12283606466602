<template>
  <div class="py-0">
    <v-card-title class="pa-0">
      <v-row no-gutters>
        <v-col cols="12" class="text-right hidden-print-only">
          <v-btn depressed color="primary" class="mt-1 mr-5" @click="print">
            <v-icon>print</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <img :src="file" ref="cropper" style="height: 13vh;" />
        </v-col>
        <v-col cols="8" class="text-center pt-2">
          <v-row no-gutters>
            <v-col cols="12" class="display-2 first-heading-font">{{company.companyName}}</v-col>
            <v-col cols="12" class="headline font-weight-bold">
              <span class="heading-font">TRAVELS & TOURS</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-center font-weight-bold title">" {{airName}} "</v-col>
        <v-col cols="12" class="caption px-2">From {{$route.query.fromDate | reportDate}} to {{$route.query.toDate | reportDate}}</v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-1">
        <v-card outlined>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th rowspan="2" class="text-center">No.</th>
                  <th rowspan="2" class="text-center">Date</th>
                  <th rowspan="2" class="text-center">PNR</th>
                  <th rowspan="2" class="text-center">Sector</th>
                  <th rowspan="2" class="text-center">To Customer</th>
                  <th rowspan="2" class="text-center">Adult</th>
                  <th rowspan="2" class="text-center">Child</th>
                  <th rowspan="2" class="text-center">Infant</th>
                  <th rowspan="2" class="text-center">Total Pax</th>
                  <th colspan="2" class="text-center">Amount</th>
                </tr>
                <tr>
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,j) in airlineDetail" :key="j">
                  <td v-if="item.code=='total'" style="width: 50px;" colspan="5" class="text-center">Total</td>
                  <td v-else style="width: 50px;" class="text-center">{{j+1}}</td>
                  <td v-if="item.code!='total'" style="width: 90px;" class="text-center">{{item.traveldate | airlineDetailReport}}</td>
                  <td v-if="item.code!='total'" class="text-center" style="width: 90px;">{{ item.pnr }}</td>
                  <td v-if="item.code!='total'" class="text-center" style="width: 100px;">{{ item.fromSector }} - {{ item.toSector }}</td>
                  <td v-if="item.code!='total'" class="text-center" style="width: 200px;">{{ item.toCustomer }}</td>
                  <td class="text-center">{{ item.adult }}</td>
                  <td class="text-center">{{ item.child }}</td>
                  <td class="text-center">{{ item.infant }}</td>
                  <td class="text-center">{{ item.pass }}</td>
                  <td class="text-center">{{ item.mmkAmount | defaultPaymentFormat}}</td>
                  <td class="text-center">{{ item.usdAmount | defaultPaymentWithDollarSignFormat }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
    </v-card-text>
  </div>
</template>
<script>
import companyInfoService from "../service/CompanyInfoService";
import reportService from "../service/ReportService";
import axios from "../config";
export default {
  data() {
    return {
      company: {},
      file: "",
      airName: "",
      dateType: "date",
      airlineDetail: []
    };
  },
  mounted: function() {
    this.getCompanyInfo();
    if (this.$route.query.airId != undefined || this.$route.query.airId != null) {
        this.airId = this.$route.query.airId;
    }
    if (this.$route.query.airName != undefined || this.$route.query.airName != null) {
        this.airName = this.$route.query.airName;
    }
    if (this.$route.query.fromDate != undefined) {
      this.fromDateValue = this.formatDate(this.$route.query.fromDate);
    }
    if (this.$route.query.toDate != undefined) {
      this.toDateValue = this.formatDate(this.$route.query.toDate);
    }
    this.getAirlineDetailReport(Number(this.airId));
  },
  methods: {
    getCompanyInfo: function() {
      companyInfoService.getCompanyInfo().then(response => {
        this.company = Object.assign({}, response);
        this.file = `${axios.defaults.baseURL}/companyLogo/${response.logo}`;
      });
    },
    getAirlineDetailReport: function() {
      this.airlineDetail.splice(0, this.airlineDetail.length);
      reportService
        .getAirlineDetailReport({
          fromDate: this.formatDateUrl(this.fromDateValue),
          toDate: this.formatDateUrl(this.toDateValue),
          airlineId: this.airId
        })
        .then(response => {
            var usdAmount = 0;
            var mmkAmount = 0;
            var adult = 0;
            var infant = 0;
            var child = 0;
            var pass = 0;
            response.forEach(detail => {
                usdAmount += detail.usdAmount;
                mmkAmount += detail.mmkAmount;
                adult += detail.adult;
                infant += detail.infant;
                child += detail.child;
                pass += detail.pass;
            });
            response.push({
                code : "total",
                usdAmount:usdAmount,
                mmkAmount:mmkAmount,
                adult:adult,
                infant:infant,
                child:child,
                pass:pass,
            })
          this.airlineDetail.push(...response);
        });
    },
    print: function() {
      window.print();
    },
    formatDate: function(date) {
      if (!date) return null;
      var [year, month, day] = date.split("-");
      if (day == undefined) {
        day = "01";
      }
      if (month == undefined) {
        month = "01";
      }
      if (this.dateType == "date") {
        return `${day}-${month}-${year}`;
      } else if (this.dateType == "month") {
        return `${month}-${year}`;
      } else {
        return `${year}`;
      }
    },
    formatDateUrl(date) {
      //if (!date) return null;
      if (this.dateType == "month") {
        const [month, year] = date.split("-");
        return `01-${month}-${year}`;
      } else if (this.dateType == "year") {
        const [year] = date.split("-");
        return `01-01-${year}`;
      }
      return date;
    }
  },
  computed: {
    toDateValue: {
      get: function() {
        var toDatetemp = "";
        toDatetemp = this.formatDate(this.toDate);
        return toDatetemp;
      },
      set: function(value) {
        this.toDate = value;
      }
    },
    fromDateValue: {
      get: function() {
        var fromDatetemp = "";
        fromDatetemp = this.formatDate(this.fromDate);
        return fromDatetemp;
      },
      set: function(value) {
        this.fromDate = value;
      }
    }
  }
};
</script>
<style scoped>
@font-face {
  font-family: "Handage";
  src: url("/font/HANDA__.woff") format("woff");
  src: url("/font/HANDA.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Haettenschweiler";
  src: url("/font/Haettenschweiler.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.heading-font {
  font-family: "Handage";
  letter-spacing: 0.3em;
}
.first-heading-font {
  font-family: "Haettenschweiler" !important;
  font-weight: 300;
  letter-spacing: 0.1em !important;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #ffffff;
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
tr td {
  padding: 0px 3px 0px 3px !important;
  font-size: 12px;
}
tr th {
  font-size: 12px;
  padding: 0px 2px 0px 2px !important;
  font-weight: 900;
}
</style>